export const isDevelopment = process.env.isDevelopment;
export const EP_WEBSITE_TERMS_CONDITIONS_BASE_URL =
  process.env.REACT_APP_EP_WEBSITE_TERMS_CONDITIONS_BASE_URL;
export const EP_WEBSITE_PRIVACY_POLICY_BASE_URL =
  process.env.REACT_APP_EP_WEBSITE_PRIVACY_POLICY_BASE_URL;
export const REACT_APP_PARTNER_WEBSITE_URL = process.env.REACT_APP_PARTNER_WEBSITE_URL;
export const REACT_APP_FLASHES_SERVER_LESS_API_BASE_URL =
  process.env.REACT_APP_FLASHES_SERVER_LESS_API_BASE_URL;

const sliderObj = (props = {}) => {
  return {
    id: 3,
    label: "Slider",
    activeRoutes: ["/admin/slider"],
    url: "/admin/slider",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
    ...props,
  };
};

export const allTabRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  {
    id: 1,
    label: "Devices",
    activeRoutes: [
      "/admin/all/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",

      "/admin/livelink/devices/view/:id",
      "/admin/airlink/device/view/:id",
      "/admin/safelink/device/view/:id",
      "/admin/bulb/device/view/:id",
      "/admin/bulb/device/view/device-history/:serial_number",
    ],
    url: "/admin/all/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  {
    id: 2,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
      "/admin/customer/auto-sync/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },
  sliderObj({}),
];

export const adminRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },

  {
    id: 2,
    label: "Manage Devices",
    activeRoutes: [
      "/admin/electricity-advisor/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",
    ],
    url: "/admin/electricity-advisor/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: true,
    subList: [
      {
        label: "Electricity Advisor",
        activeRoutes: ["/admin/electricity-advisor/devices", "/admin/devices/view/:id"],
        url: "/admin/electricity-advisor/devices",
        collapse: false,
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
      },
      {
        label: "Supergateways",
        activeRoutes: ["/admin/electricity-advisor/supergateways", "/admin/supergateways/view/:id"],
        url: "/admin/electricity-advisor/supergateways",
        collapse: false,
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
        collapse: false,
      },
    ],
  },

  // {
  //   id: 2,
  //   label: 'Devices',
  //   activeRoutes: [
  //     '/admin/electricity-advisor/devices',
  //     '/admin/devices/view/:id',
  //     '/admin/device/view/firmware-history/:id',
  //     '/admin/device/view/impulse-rate-history/:id',
  //     '/admin/device/view/device-history/:id',
  //   ],
  //   url: '/admin/electricity-advisor/devices',
  //   Icon: '/images/Devices-icon.svg',
  //   activeIcon: '/images/Devices-icon1.svg',
  //   collapse: false
  // },
  {
    id: 3,
    label: "Site",
    activeRoutes: [
      "/admin/electricity-advisor/sites",
      "/admin/sites/view/:id",
      // '/admin/site/view/firmware-history/:id',
      // '/admin/site/view/impulse-rate-history/:id',
      // '/admin/site/view/device-history/:id',
    ],
    url: "/admin/electricity-advisor/sites",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  {
    id: 10,
    label: "Jobs",
    activeRoutes: [
      "/admin/electricity-advisor/jobs",
      "/admin/job/view/:id",
      // '/admin/site/view/firmware-history/:id',
      // '/admin/site/view/impulse-rate-history/:id',
      // '/admin/site/view/device-history/:id',
    ],
    url: "/admin/electricity-advisor/jobs",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  {
    id: 4,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
      "/admin/customer/auto-sync/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },

  // {
  //   id: 5,
  //   label: 'Manage Electricians',
  //   activeRoutes: [
  //     '/admin/electricians',
  //     '/admin/electrician/view/:id',
  //     '/admin/electrician',
  //     '/admin/electrician/:id',
  //     '/admin/electricians-installers',
  //     '/admin/electrician-installer/view/:id',
  //     '/admin/aggregators',
  //     '/admin/electrician-installer/device/view/:id'
  //   ],
  //   Icon: '/images/Manage-Electricians.svg',
  //   activeIcon: '/images/Manage-Electricians1.svg',
  //   collapse: true,
  //   subList: [
  //     {
  //       label: 'Aggregators',
  //       activeRoutes: [
  //         '/admin/aggregators',
  //       ],
  //       url: '/admin/aggregators',
  //       subIcon: '/images/Agreegators.svg',
  //       activeIcon: '/images/Agreegators1.svg',
  //       collapse: false
  //     },
  //     {
  //       label: 'Electricians',
  //       activeRoutes: [
  //         '/admin/electricians',
  //         '/admin/electrician/view/:id',
  //         '/admin/electrician',
  //         '/admin/electrician/:id',
  //       ],
  //       url: '/admin/electricians',
  //       collapse: false,
  //       subIcon: '/images/Electrician.svg',
  //       activeIcon: '/images/Electrician1.svg'
  //     },
  //     {
  //       label: 'Field Workers',
  //       activeRoutes: [
  //         '/admin/electricians-installers',
  //         '/admin/electrician-installer/view/:id',
  //         '/admin/electrician-installer/device/view/:id'
  //       ],
  //       url: '/admin/electricians-installers',
  //       subIcon: '/images/ElecInstallers.svg',
  //       activeIcon: '/images/ElecInstallers1.svg',
  //       collapse: false
  //     },
  //   ]
  // },

  // {
  //   id: 6,
  //   label: 'Installer Agents',
  //   activeRoutes: [
  //     '/admin/installer-agents',
  //     '/admin/installer-agent/view/:id',
  //     '/admin/installer-agent/view/device/:id'
  //   ],
  //   url: '/admin/installer-agents',
  //   Icon: '/images/Installer-Agents-icon.svg',
  //   activeIcon: '/images/Installer-Agents-icon1.svg',
  //   collapse: false

  // },

  {
    id: 6,
    label: "Tariffs",
    activeRoutes: ["/admin/tariffs", "/admin/tariff", "/admin/tariff/:id"],
    url: "/admin/tariffs",
    Icon: "/images/Tariffs.svg",
    activeIcon: "/images/Tariffs1.svg",
    collapse: false,
  },

  // {
  //   id: 8,
  //   label: 'Electricity Retailers',
  //   activeRoutes: ['/admin/electricity-retailers'],
  //   url: '/admin/electricity-retailers',
  //   Icon: '/images/Electricity-Retailers.svg',
  //   activeIcon: '/images/Electricity-Retailers1.svg',
  //   collapse: false
  // },

  {
    id: 7,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
      // {
      //   label: 'Groups',
      //   activeRoutes: ['/admin/OTA-groups', '/admin/OTA-groups/:id'],
      //   url: '/admin/OTA-groups',
      //   subIcon: '/images/group-2-line.svg',
      //   activeIcon: '/images/group-2-line-(1).svg',
      //   collapse: false
      // },
    ],
  },

  // {
  //   id: 8,
  //   label: 'Suppression List',
  //   activeRoutes: [
  //     '/admin/weekly-reports'
  //   ],
  //   url: '/admin/weekly-reports',
  //   Icon: '/images/weekly-reports-disable.svg',
  //   activeIcon: '/images/weekly reports enable.svg',
  //   collapse: false
  // },
  // {
  //   id: 9,
  //   label: 'Slider',
  //   activeRoutes: ['/admin/electricity-advisor/slider'],
  //   url: '/admin/electricity-advisor/slider',
  //   Icon: '/images/dashboard-icon-(1).svg',
  //   activeIcon: '/images/dashboard-icon.svg',
  //   collapse: false
  // },
  sliderObj({ id: 8 }),
];

export const lightningRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  {
    id: 2,
    label: "Devices",
    activeRoutes: [
      "/admin/bulb/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",
      "/admin/bulb/device/view/:id",
    ],
    url: "/admin/bulb/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  {
    id: 3,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
      "/admin/customer/auto-sync/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },
  {
    id: 7,
    label: "Tariffs",
    activeRoutes: ["/admin/tariffs", "/admin/tariff", "/admin/tariff/:id"],
    url: "/admin/tariffs",
    Icon: "/images/Tariffs.svg",
    activeIcon: "/images/Tariffs1.svg",
    collapse: false,
  },

  // {
  //   id: 8,
  //   label: 'Electricity Retailers',
  //   activeRoutes: ['/admin/electricity-retailers'],
  //   url: '/admin/electricity-retailers',
  //   Icon: '/images/Electricity-Retailers.svg',
  //   activeIcon: '/images/Electricity-Retailers1.svg',
  //   collapse: false
  // },

  {
    id: 9,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
      {
        label: "Groups",
        activeRoutes: ["/admin/OTA-groups", "/admin/OTA-groups/:id"],
        url: "/admin/OTA-groups",
        subIcon: "/images/group-2-line.svg",
        activeIcon: "/images/group-2-line-(1).svg",
        collapse: false,
      },
    ],
  },

  {
    id: 10,
    label: "Suppression List",
    activeRoutes: ["/admin/weekly-reports"],
    url: "/admin/weekly-reports",
    Icon: "/images/weekly-reports-disable.svg",
    activeIcon: "/images/weekly reports enable.svg",
    collapse: false,
  },
  sliderObj({ id: 11 }),
];

export const heatPumpRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  {
    id: 2,
    label: "Devices",
    activeRoutes: [
      "/admin/heat-pump/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",
      "/admin/heat-pump/device/view/:id",
    ],
    url: "/admin/heat-pump/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  {
    id: 3,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
      "/admin/customer/auto-sync/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },
  {
    id: 7,
    label: "Tariffs",
    activeRoutes: ["/admin/tariffs", "/admin/tariff", "/admin/tariff/:id"],
    url: "/admin/tariffs",
    Icon: "/images/Tariffs.svg",
    activeIcon: "/images/Tariffs1.svg",
    collapse: false,
  },

  // {
  //   id: 8,
  //   label: 'Electricity Retailers',
  //   activeRoutes: ['/admin/electricity-retailers'],
  //   url: '/admin/electricity-retailers',
  //   Icon: '/images/Electricity-Retailers.svg',
  //   activeIcon: '/images/Electricity-Retailers1.svg',
  //   collapse: false
  // },

  {
    id: 9,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
      {
        label: "Groups",
        activeRoutes: ["/admin/OTA-groups", "/admin/OTA-groups/:id"],
        url: "/admin/OTA-groups",
        subIcon: "/images/group-2-line.svg",
        activeIcon: "/images/group-2-line-(1).svg",
        collapse: false,
      },
    ],
  },
  sliderObj({ id: 11 }),
];

export const livelinkRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  // {
  //   id: 1,
  //   label: 'Devices',
  //   activeRoutes: [
  //     '/admin/livelink/devices',
  //     '/admin/livelink/devices/view/:id',
  //     '/admin/device/view/firmware-history/:id',
  //     '/admin/livelink/device/view/device-history/:id'
  //   ],
  //   url: '/admin/livelink/devices',
  //   Icon: '/images/Devices-icon.svg',
  //   activeIcon: '/images/Devices-icon1.svg',
  //   collapse: false
  // },
  {
    id: 2,
    label: "Manage Devices",
    activeRoutes: [
      "/admin/electricity-advisor/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",
    ],
    url: "/admin/electricity-advisor/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: true,
    subList: [
      {
        label: "Emerald",
        activeRoutes: [
          "/admin/electricity-advisor/devices",
          "/admin/devices/view/:id",
          "/admin/device/view/firmware-history/:id",
          "/admin/device/view/impulse-rate-history/:id",
          "/admin/device/view/device-history/:id",
        ],
        url: "/admin/electricity-advisor/devices",
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
        collapse: false,
      },
      {
        label: "LiveLink",
        activeRoutes: [
          "/admin/livelink/devices",
          "/admin/livelink/devices/view/:id",
          "/admin/device/view/firmware-history/:id",
          "/admin/livelink/device/view/device-history/:id",
        ],
        url: "/admin/livelink/devices",
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
        collapse: false,
      },
    ],
  },
  {
    id: 3,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },
  {
    id: 4,
    label: "Tariffs",
    activeRoutes: ["/admin/tariffs", "/admin/tariff", "/admin/tariff/:id"],
    url: "/admin/tariffs",
    Icon: "/images/Tariffs.svg",
    activeIcon: "/images/Tariffs1.svg",
    collapse: false,
  },
  // {
  //   id: 6,
  //   label: 'Electricity Retailers',
  //   activeRoutes: ['/admin/electricity-retailers'],
  //   url: '/admin/electricity-retailers',
  //   Icon: '/images/Electricity-Retailers.svg',
  //   activeIcon: '/images/Electricity-Retailers1.svg',
  //   collapse: false
  // },
  {
    id: 5,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      // {
      //   label: 'Firmwares',
      //   activeRoutes: ['/admin/livelink/firmwares'],
      //   url: '/admin/livelink/firmwares',
      //   subIcon: '/images/code-box-line.svg',
      //   activeIcon: '/images/code-box-line-(1).svg',
      //   collapse: false
      // },
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
    ],
  },
  sliderObj({ id: 6 }),
];

export const safelinkRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  {
    id: 9,
    label: "Devices",
    activeRoutes: [
      "/admin/safelink/devices",
      "/admin/safelink/device/view/:id",
      "/admin/safelink/device/view/device-history/:mac_address",
      "/admin/device/view/firmware-history/:id",
    ],
    url: "/admin/safelink/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  // {
  //   id: 4,
  //   label: 'Devices',
  //   activeRoutes: [
  //     '/admin/safelink/devices',
  //     '/admin/safelink/device/view/:id',
  //     '/admin/smoke-alarm/devices',
  //     '/admin/smoke-alarm/device/view/:id'
  //   ],
  //   url: '/admin/safelink/devices',
  //   Icon: '/images/Devices-icon.svg',
  //   activeIcon: '/images/Devices-icon1.svg',
  //   collapse: true,
  //   subList: [
  //     {
  //       label: 'Safelinks',
  //       activeRoutes: ['/admin/safelink/devices', '/admin/safelink/device/view/:id'],
  //       url: '/admin/safelink/devices',
  //       subIcon: '/images/code-box-line.svg',
  //       activeIcon: '/images/code-box-line-(1).svg',
  //       collapse: false
  //     },
  //     {
  //       label: 'Smoke Alarms',
  //       activeRoutes: ['/admin/smoke-alarm/devices', '/admin/smoke-alarm/device/view/:id'],
  //       url: '/admin/smoke-alarm/devices',
  //       subIcon: '/images/code-box-line.svg',
  //       activeIcon: '/images/code-box-line-(1).svg',
  //       collapse: false
  //     }
  //   ]
  // },
  {
    id: 2,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },
  {
    id: 4,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/livelink/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
      {
        label: "Groups",
        activeRoutes: ["/admin/OTA-groups", "/admin/OTA-groups/:id"],
        url: "/admin/OTA-groups",
        subIcon: "/images/group-2-line.svg",
        activeIcon: "/images/group-2-line-(1).svg",
        collapse: false,
      },
    ],
  },
  {
    id: 5,
    label: "Slider",
    activeRoutes: ["/admin/safelink/slider"],
    url: "/admin/safelink/slider",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  sliderObj({ id: 6 }),
];

export const airlinkRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  {
    id: 2,
    label: "Devices",
    activeRoutes: [
      "/admin/airlink/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",
      "/admin/airlink/device/view/:id",
    ],
    url: "/admin/airlink/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  {
    id: 3,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
      "/admin/customer/trial/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },

  // {
  //   id: 4,
  //   label: 'Manage Agencies',
  //   activeRoutes: [
  //     '/admin/installer-agencies',
  //     '/admin/installer-agency',
  //     '/admin/installer-agency/view/:id',
  //     '/admin/installer-agency/:id',
  //     '/admin/integration/view/:id',
  //     '/admin/integrations'
  //   ],
  //   url: '/admin/installer-agencies',
  //   Icon: '/images/Manage-Agencies-icon.svg',
  //   activeIcon: '/images/Manage-Agencies-icon1.svg',
  //   collapse: true,
  //   subList: [
  //     {
  //       label: 'Integrations',
  //       activeRoutes: [
  //         '/admin/integrations',
  //         '/admin/integration/view/:id'
  //       ],
  //       url: '/admin/integrations',
  //       collapse: false,
  //       subIcon: '/images/team-line.svg',
  //       activeIcon: '/images/team-line(1).svg'

  //     },
  //     {
  //       label: 'Agencies',
  //       activeRoutes: [
  //         '/admin/installer-agencies',
  //         '/admin/installer-agency/view/:id',
  //         '/admin/installer-agency',
  //         '/admin/installer-agency/:id'
  //       ],
  //       url: '/admin/installer-agencies',
  //       subIcon: '/images/building-2-line.svg',
  //       activeIcon: '/images/building-2(1).svg',
  //       collapse: false
  //     }

  //   ]
  // },
  // {
  //   id: 6,
  //   label: 'Installer Agents',
  //   activeRoutes: [
  //     '/admin/installer-agents',
  //     '/admin/installer-agent/view/:id',
  //     '/admin/installer-agent/view/device/:id'
  //   ],
  //   url: '/admin/installer-agents',
  //   Icon: '/images/Installer-Agents-icon.svg',
  //   activeIcon: '/images/Installer-Agents-icon1.svg',
  //   collapse: false

  // },

  {
    id: 9,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
      {
        label: "Groups",
        activeRoutes: ["/admin/OTA-groups", "/admin/OTA-groups/:id"],
        url: "/admin/OTA-groups",
        subIcon: "/images/group-2-line.svg",
        activeIcon: "/images/group-2-line-(1).svg",
        collapse: false,
      },
    ],
  },
  {
    id: 5,
    label: "Slider",
    activeRoutes: ["/admin/airlink/slider"],
    url: "/admin/airlink/slider",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  sliderObj({ id: 6 }),
];

export const agencyRoutes = [
  {
    id: 1,
    label: "Devices",
    activeRoutes: ["/agency/devices", "/agency/device/view/:id"],
    url: "/agency/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
  },
  {
    id: 2,
    label: "Manage Electricians",
    activeRoutes: [
      "/agency/electricians",
      "/agency/electrician/view/:id",
      "/agency/electricians-agents",
      "/agency/electricians-agent/view/:id",
      "/agency/dataforce-devices",
      "/agency/dataforce-device/:id",
    ],
    Icon: "/images/Manage-Electricians.svg",
    activeIcon: "/images/Manage-Electricians1.svg",
    collapse: true,
    subList: [
      {
        label: "Electrician",
        activeRoutes: ["/agency/electricians", "/agency/electrician/view/:id"],
        url: "/agency/electricians",
        collapse: false,
        subIcon: "/images/Electrician.svg",
        activeIcon: "/images/Electrician1.svg",
      },
      {
        label: "Field Workers",
        activeRoutes: ["/agency/electricians-agents", "/agency/electricians-agent/view/:id"],
        url: "/agency/electricians-agents",
        subIcon: "/images/ElecInstallers.svg",
        activeIcon: "/images/ElecInstallers1.svg",
        collapse: false,
      },
      {
        label: "Devices - VEU",
        activeRoutes: ["/agency/dataforce-devices", "/agency/dataforce-device/:id"],
        url: "/agency/dataforce-devices",
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
        collapse: false,
      },
    ],
  },
  {
    id: 3,
    label: "Installer Agents",
    activeRoutes: [
      "/agency/installation-agents",
      "/agency/installation-agent",
      "/agency/installation-agent/view/:id",
      "/agency/installation-agent/:id",
    ],
    url: "/agency/installation-agents",
    Icon: "/images/Installer-Agents-icon.svg",
    activeIcon: "/images/Installer-Agents-icon1.svg",
  },
  {
    id: 4,
    label: "Profile",
    activeRoutes: ["/agency/profile"],
    url: "/agency/profile",
    Icon: "/images/Profile-icon.svg",
    activeIcon: "/images/Profile-icon-white.svg",
  },
  sliderObj({ id: 5 }),
];

export const electricianRoutes = [
  {
    id: 8,
    label: "Devices",
    activeRoutes: ["/electrician/devices", "/electrician/device/view/:id"],
    url: "/electrician/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
  },
  {
    id: 9,
    label: "Installer Agents",
    activeRoutes: [
      "/electrician/installer-agents",
      "/electrician/installer-agent",
      "/electrician/installer-agent/view/:id",
      "/electrician/installer-agent/:id",
    ],
    url: "/electrician/installer-agents",
    Icon: "/images/ElecInstallers.svg",
    activeIcon: "/images/ElecInstallers1.svg",
  },
  // {
  //   id: 10,
  //   label: 'Profile',
  //   activeRoutes: ['/electrician/profile'],
  //   url: '/electrician/profile',
  //   Icon: '/images/Profile-icon.svg',
  //   activeIcon: '/images/Profile-icon-white.svg',
  // },
  sliderObj({ id: 6 }),
];

export const emeraldRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },

  {
    id: 2,
    label: "Devices",
    activeRoutes: [
      "/admin/electricity-advisor/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",
    ],
    url: "/admin/electricity-advisor/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: false,
  },
  {
    id: 4,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
      "/admin/customer/auto-sync/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },

  {
    id: 6,
    label: "Tariffs",
    activeRoutes: ["/admin/tariffs", "/admin/tariff", "/admin/tariff/:id"],
    url: "/admin/tariffs",
    Icon: "/images/Tariffs.svg",
    activeIcon: "/images/Tariffs1.svg",
    collapse: false,
  },

  {
    id: 7,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
    ],
  },
  sliderObj({ id: 8 }),
];

export const supergatewayRoutes = [
  {
    id: 1,
    label: "Dashboard",
    activeRoutes: ["/admin/dashboard"],
    url: "/admin/dashboard",
    Icon: "/images/dashboard-icon-(1).svg",
    activeIcon: "/images/dashboard-icon.svg",
    collapse: false,
  },
  {
    id: 17,
    label: "Manage Sites",
    activeRoutes: [
      "/admin/electricity-advisor/sites",
      "/admin/sites/view/:id",
      "/admin/electricity-advisor/jobs",
      "/admin/job/view/:id",
    ],
    url: "/admin/electricity-advisor",
    Icon: "/images/Manage-Agencies-icon.svg",
    activeIcon: "/images/Manage-Agencies-icon1.svg",
    collapse: true,
    subList: [
      {
        label: "Site",
        activeRoutes: [
          "/admin/electricity-advisor/sites",
          "/admin/sites/view/:id",
          // '/admin/site/view/firmware-history/:id',
          // '/admin/site/view/impulse-rate-history/:id',
          // '/admin/site/view/device-history/:id',
        ],
        url: "/admin/electricity-advisor/sites",
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
        collapse: false,
      },
      {
        label: "Jobs",
        activeRoutes: [
          "/admin/electricity-advisor/jobs",
          "/admin/job/view/:id",
          // '/admin/site/view/firmware-history/:id',
          // '/admin/site/view/impulse-rate-history/:id',
          // '/admin/site/view/device-history/:id',
        ],
        url: "/admin/electricity-advisor/jobs",
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
        collapse: false,
      },
    ],
  },
  {
    id: 2,
    label: "Manage Devices",
    activeRoutes: [
      "/admin/electricity-advisor/devices",
      "/admin/devices/view/:id",
      "/admin/device/view/firmware-history/:id",
      "/admin/device/view/impulse-rate-history/:id",
      "/admin/device/view/device-history/:id",
    ],
    url: "/admin/electricity-advisor/devices",
    Icon: "/images/Devices-icon.svg",
    activeIcon: "/images/Devices-icon1.svg",
    collapse: true,
    subList: [
      {
        label: "Emerald",
        activeRoutes: ["/admin/electricity-advisor/devices", "/admin/devices/view/:id"],
        url: "/admin/electricity-advisor/devices",
        collapse: false,
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
      },
      {
        label: "Supergateways",
        activeRoutes: [
          "/admin/electricity-advisor/supergateways",
          "/admin/supergateways/view/:id",
          "/admin/devices/view/:id",
        ],
        url: "/admin/electricity-advisor/supergateways",
        collapse: false,
        subIcon: "/images/Devices-icon.svg",
        activeIcon: "/images/Devices-icon1.svg",
        collapse: false,
      },
    ],
  },
  {
    id: 3,
    label: "Customers",
    activeRoutes: [
      "/admin/customers",
      "/admin/customer/view/:id",
      "/admin/customer/customer-email-history/:id",
      "/admin/customer/property/view/:id",
      "/admin/customer/property/view-history/:id",
      "/admin/customer/property/property-timeline/:id",
      "/admin/customer/auto-sync/:id",
    ],
    url: "/admin/customers",
    Icon: "/images/Customers-icon.svg",
    activeIcon: "/images/Customers-icon-(1).svg",
    collapse: false,
  },
  {
    id: 10,
    label: "Manage Agencies",
    activeRoutes: [
      "/admin/installer-agencies",
      "/admin/installer-agency",
      "/admin/installer-agency/view/:id",
      "/admin/installer-agency/:id",
      "/admin/integration/view/:id",
      "/admin/integrations",
      "/admin/installer-agents",
      "/admin/installer-agent",
      "/admin/installer-agent/view/:id",
      "/admin/installer-agent/:id",
    ],
    url: "/admin/installer-agencies",
    Icon: "/images/Manage-Agencies-icon.svg",
    activeIcon: "/images/Manage-Agencies-icon1.svg",
    collapse: true,
    subList: [
      {
        label: "Installer Agents",
        activeRoutes: [
          "/admin/installer-agents",
          "/admin/installer-agent",
          "/admin/installer-agent/view/:id",
          "/admin/installer-agent/:id",
        ],
        url: "/admin/installer-agents",
        subIcon: "/images/Installer-Agents-icon.svg",
        activeIcon: "/images/Installer-Agents-icon1.svg",
      },
      {
        label: "Agencies",
        activeRoutes: [
          "/admin/installer-agencies",
          "/admin/installer-agency/view/:id",
          "/admin/installer-agency",
          "/admin/installer-agency/:id",
        ],
        url: "/admin/installer-agencies",
        subIcon: "/images/building-2-line.svg",
        activeIcon: "/images/building-2(1).svg",
        collapse: false,
      },
    ],
  },
  {
    id: 4,
    label: "Tariffs",
    activeRoutes: ["/admin/tariffs", "/admin/tariff", "/admin/tariff/:id"],
    url: "/admin/tariffs",
    Icon: "/images/Tariffs.svg",
    activeIcon: "/images/Tariffs1.svg",
    collapse: false,
  },
  {
    id: 5,
    label: "Manage Firmwares",
    activeRoutes: [
      "/admin/firmwares",
      "/admin/OTAs",
      "/admin/OTA_History",
      "/admin/rules",
      "/admin/OTA-groups",
      "/admin/OTA-groups/:id",
    ],
    url: "/admin/firmwares",
    Icon: "/images/Manage-Firmwares.svg",
    activeIcon: "/images/Manage-Firmwares1.svg",
    collapse: true,
    subList: [
      {
        label: "Firmwares",
        activeRoutes: ["/admin/firmwares"],
        url: "/admin/firmwares",
        subIcon: "/images/code-box-line.svg",
        activeIcon: "/images/code-box-line-(1).svg",
        collapse: false,
      },
      {
        label: "OTA",
        activeRoutes: ["/admin/OTAs"],
        url: "/admin/OTAs",
        subIcon: "/images/device-recover-line.svg",
        activeIcon: "/images/device-recover-line(1).svg",
        collapse: false,
      },
      {
        label: "OTA History",
        activeRoutes: ["/admin/OTA_History"],
        url: "/admin/OTA_History",
        subIcon: "/images/history-line.svg",
        activeIcon: "/images/history-line-(1).svg",
        collapse: false,
      },
      {
        label: "Rules",
        activeRoutes: ["/admin/rules"],
        url: "/admin/rules",
        subIcon: "/images/file-list-line.svg",
        activeIcon: "/images/file-list-line(1).svg",
        collapse: false,
      },
    ],
  },
  sliderObj({ id: 6 }),
];

export const colors = {
  primary: "#00a88e",
  secondary: "#4c6072",
  // "text-main": '#333333',
  // "border-main": '#4c607233',
  // "bg-light": '#f6f8fa',
  // "text-error": '#f00',
  // "success-text": '#008000',
  // "color-white": '#fff',
};

export const unitType = [
  "Apartment",
  "Cottage",
  "Factory",
  "Flat",
  "House",
  "Office",
  "Room",
  "Shed",
  "Shop",
  "Showroom",
  "Site",
  "Suite",
  "Townhouse",
  "Unit",
  "Villa",
];

export const streetType = [
  "Access",
  "Alley",
  "Alleyway",
  "Amble",
  "Anchorage",
  "Approach",
  "Arcade",
  "Artery",
  "Avenue",
  "Bank",
  "Basin",
  "Bay",
  "Beach",
  "Bend",
  "Block",
  "Boulevard",
  "Boundary",
  "Bowl",
  "Brace",
  "Brae",
  "Branch",
  "Break",
  "Bridge",
  "Broadway",
  "Brow",
  "Bypass",
  "Byway",
  "Causeway",
  "Centre",
  "Centreway",
  "Chase",
  "Circle",
  "Circlet",
  "Circuit",
  "Circus",
  "Close",
  "Colonnade",
  "Common",
  "Community",
  "Concourse",
  "Connection",
  "Copse",
  "Corner",
  "Corso",
  "Course",
  "Court",
  "Courtyard",
  "Cove",
  "Creek",
  "Crescent",
  "Crest",
  "Crief",
  "Cross",
  "Crossing",
  "Crossroad",
  "Crossway",
  "Cruiseway",
  "Cul De Sac",
  "Cutting",
  "Dale",
  "Dell",
  "Deviation",
  "Dip",
  "Distributor",
  "Downs",
  "Drive",
  "Driveway",
  "Easement",
  "Edge",
  "Elbow",
  "End",
  "Entrance",
  "Esplanade",
  "Estate",
  "Expressway",
  "Extension",
  "Fair",
  "Fairway",
  "Fire Track",
  "Firetrail",
  "Flat",
  "Follow",
  "Footway",
  "Foreshore",
  "Formation",
  "Freeway",
  "Front",
  "Frontage",
  "Gap",
  "Garden",
  "Gardens",
  "Gate",
  "Gates",
  "Gateway",
  "Glade",
  "Glen",
  "Grange",
  "Green",
  "Ground",
  "Grove",
  "Gully",
  "Heath",
  "Heights",
  "Highroad",
  "Highway",
  "Hill",
  "Hillside",
  "Interchange",
  "Intersection",
  "Island",
  "Junction",
  "Key",
  "Knoll",
  "Landing",
  "Lane",
  "Laneway",
  "Lees",
  "Line",
  "Link",
  "Little",
  "Location",
  "Lookout",
  "Loop",
  "Lower",
  "Mall",
  "Meander",
  "Mew",
  "Mews",
  "Motorway",
  "Mount",
  "Nook",
  "Outlook",
  "Parade",
  "Paradise",
  "Park",
  "Parklands",
  "Parkway",
  "Part",
  "Pass",
  "Path",
  "Pathway",
  "Piazza",
  "Pier",
  "Place",
  "Plateau",
  "Plaza",
  "Pocket",
  "Point",
  "Port",
  "Promenade",
  "Pursuit",
  "Quad",
  "Quadrangle",
  "Quadrant",
  "Quay",
  "Quays",
  "Racecourse",
  "Railway Siding",
  "Ramble",
  "Ramp",
  "Range",
  "Reach",
  "Reserve",
  "Rest",
  "Retreat",
  "Return",
  "Ride",
  "Ridge",
  "Ridgeway",
  "Right Of Way",
  "Ring",
  "Rise",
  "River",
  "Riverway",
  "Riviera",
  "Road",
  "Roads",
  "Roadside",
  "Roadway",
  "Ronde",
  "Rosebowl",
  "Rotary",
  "Round",
  "Route",
  "Row",
  "Rowe",
  "Rue",
  "Run",
  "Service Way",
  "Siding",
  "Slope",
  "Sound",
  "Spur",
  "Square",
  "Stairs",
  "State Highway",
  "Station",
  "Steps",
  "Straight",
  "Strand",
  "Street",
  "Strip",
  "Subway",
  "Tarn",
  "Terrace",
  "Thoroughfare",
  "Tollway",
  "Top",
  "Tor",
  "Tower",
  "Towers",
  "Track",
  "Trail",
  "Trailer",
  "Triangle",
  "Trunkway",
  "Turn",
  "Underpass",
  "Upper",
  "Vale",
  "Viaduct",
  "View",
  "Village",
  "Villas",
  "Vista",
  "Wade",
  "Walk",
  "Walkway",
  "Waters",
  "Way",
  "Wharf",
  "Wood",
  "Wynd",
  "Yard",
];

export const levelType = ["Block", "Floor", "Level", "Lot"];

export const state = ["NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

export const streetTypeSuffix = [
  "Central",
  "East",
  "Extension",
  "Lower",
  "North",
  "North East",
  "North West",
  "South",
  "South East",
  "South West",
  "Upper",
  "West",
];

export const paginationList = [
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "200", value: 200 },
  { label: "500", value: 500 },
];

export const passwordRegValidationKey = {
  noUpperCase: true,
  noLowerCase: true,
  noDigit: true,
  noLength: true,
};

export const phoneRegex = /^[+\d](?:.*\d)?$/;

export const lettersAndDigitsRegex = /^[a-zA-Z0-9_.-]*$/;

export const onlyNumberRegex = /^[0-9]+$/;

export const numberAndDecimalRegex = /^[0-9]+\.?[0-9]*$/;

export const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

export function bytesToMegaBytes(bytes) {
  return bytes / (1024 * 1024);
}
export function bytesToKiloBytes(bytes) {
  return bytes / 1024;
}

export function abbreviateNumber(num, digits) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
