import { apiDelete, apiGet, apiPost, apiPut } from "../../../utils";

const SLIDER_URL = "/api/homepage/slider";
const UPDATE_URL = "/api/homepage/slider/update";

export const getAllSlidesAPI = () => {
  return apiGet(SLIDER_URL, null);
};

export const addSlideAPI = (data) => {
  return apiPost(SLIDER_URL, data);
};
export const updateSlideAPI = (data) => {
  return apiPost(UPDATE_URL, data);
};
export const deleteSlideAPI = (id) => {
  return apiDelete(`${SLIDER_URL}?id=${id}`, null);
};
