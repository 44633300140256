import { addSlideAPI, deleteSlideAPI, getAllSlidesAPI, updateSlideAPI } from "./api's";
import * as commonActions from "../../common/actions";
import { errorMessage } from "../../../utils";

export const GET_ALL_SLIDES_REQUEST = "GET_ALL_SLIDES_REQUEST";
export const GET_ALL_SLIDES_SUCCESS = "GET_ALL_SLIDES_SUCCESS";
export const GET_ALL_SLIDES_FAILED = "GET_ALL_SLIDES_FAILED";

export const getAllSlides = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_ALL_SLIDES_REQUEST,
    });

    getAllSlidesAPI()
      .then((res) => {
        dispatch({
          type: GET_ALL_SLIDES_SUCCESS,
          payload: (res && res.info) || {},
          total: res.total,
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_SLIDES_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const CLEAR_STORE_FOR_SLIDES = "CLEAR_STORE_FOR_SLIDES";

export const clearStoreForSlider = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STORE_FOR_SLIDES,
    });
  };
};

export const ADD_SLIDE_REQUEST = "ADD_SLIDE_REQUEST";
export const ADD_SLIDE_SUCCESS = "ADD_SLIDE_SUCCESS";
export const ADD_SLIDE_FAILED = "ADD_SLIDE_FAILED";

export const createNewSlider = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: ADD_SLIDE_REQUEST,
    });
    addSlideAPI(data)
      .then((res) => {
        dispatch({
          type: ADD_SLIDE_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: ADD_SLIDE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const DELETE_SLIDE_REQUEST = "DELETE_SLIDE_REQUEST";
export const DELETE_SLIDE_SUCCESS = "DELETE_SLIDE_SUCCESS";
export const DELETE_SLIDE_FAILED = "DELETE_SLIDE_FAILED";

export const deleteSlider = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: DELETE_SLIDE_REQUEST,
    });
    deleteSlideAPI(id)
      .then((res) => {
        dispatch({
          type: DELETE_SLIDE_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: DELETE_SLIDE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const UPDATE_SLIDE_REQUEST = "UPDATE_SLIDE_REQUEST";
export const UPDATE_SLIDE_SUCCESS = "UPDATE_SLIDE_SUCCESS";
export const UPDATE_SLIDE_FAILED = "UPDATE_SLIDE_FAILED";

export const updateSlide = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_SLIDE_REQUEST,
    });
    updateSlideAPI(data)
      .then((res) => {
        dispatch({
          type: UPDATE_SLIDE_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SLIDE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
